import { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import RPC from "./web3RPC";
import { web3auth } from "../config/config";
import { apiService } from "../service/api.service";
import Web3 from "web3";
import swal from "sweetalert";

const NotConnectWalletPopup = (props) => {

// console.log(props)

const [userDetail, setUserDetail] = useState();
const [chain, setChain] = useState()
const [account, setAccount] = useState()
const accountRef = useRef(0)
const [balance, setBalance] = useState()
const [provider, setProvider] = useState(false);
const testWeb3Auth = async () => {
  if (web3auth) {
    try {
      console.log("Connecting to Web3Auth...");
      props.handleClose();
      let provider = await web3auth.connect();
      // dispatch(setWeb3AuthProvider(provider)); // Set global provider in Redux
      console.log("Web3Auth connected successfully!");
      let account = await getAccounts();
      await getUserInfo();
      await getChainId();
      await getBalance();
      console.log("account:::::::", account);
      // if(accountRef.current >0){
      await Web3AuthLogin(accountRef.current)

      // }

      // setLoggedIn(true); // Set local state
    } catch (error) {
      console.error("Error during Web3Auth connection:", error);
    }
  } else {
    console.log("Web3Auth not initialized yet.");
  }
};

const getUserInfo = async () => {
  const user = await web3auth.getUserInfo(); // Fetch user info from Web3Auth
  setUserDetail(user)
};

const getChainId = async () => {
  if (!provider) {
    console.log("provider not initialized yet");
    return;
  }
  const rpc = new RPC(provider);
  const chainId = await rpc.getChainId();
  setChain(chainId)

};

const getAccounts = async () => {
  if (!web3auth) {
    console.log("provider not initialized yet");
    return;
  }
  const rpc = new RPC(web3auth.provider);
  const address = await rpc.getAccounts();
  console.log("address:::", address);
  setAccount(address) // Store address
  accountRef.current = address
  // if(account){
  //   console.log("account",account)
  //   await Web3AuthLogin(account)
  // }

};

const getBalance = async () => {
  try {
    if (!provider) {
      console.log("Provider not initialized yet");
      return;
    }

    console.log("Provider initialized:", provider);

    const rpc = new RPC(provider);

    // Fetch balance from the RPC
    const balanceInWei = await rpc?.getBalance();

    if (!balanceInWei) {
      throw new Error("Invalid balance fetched");
    }

    console.log("Balance in Wei:", balanceInWei);

    // Ensure you use a valid unit here (e.g., ether)
    let webb3 = new Web3(window.ethereum);
    const balanceInEther =webb3.utils.fromWei(balanceInWei, "ether");

    console.log("Balance in Ether:", balanceInEther);
    setBalance(balanceInEther);

  } catch (error) {
    console.error("Error fetching balance:", error);
  }
};

// const logout = () => {
//   web3auth.logout(); // Logout Web3Auth
//   setIsLoggedIn(false); // Reset login state
//   localStorage.removeItem("isLoggedIn"); // Remove login state from localStorage
// };

/* login with web3auth */

async function Web3AuthLogin(walletddress) {
  try {
    let data = {
      wallet_address: walletddress?.toLowerCase(),
      user_type: "CUSTODIAL"
    }
    const response = await apiService.loginWithWeb3(data);
    if (response.status == 200) {

      console.log("login>>>>>>>>>>>", response.data);
      localStorage.setItem("connected_wallet", walletddress)
      localStorage.setItem("is_walletConnected", true)
      localStorage.setItem("loginType", "Web3Auth")
      localStorage.setItem("user_type", "CUSTODIAL")
      localStorage.setItem("token", response.data.data.token)
      await getBalance();
      await getChainId();
      await getUserInfo();
      window.location.reload();
    }
  } catch (error) {
    console.log("=====error==", error)
    //   setLoader(false);
    if (error?.response?.status == 401) {
      swal({ text: "Unauthorized", button: "OK" }).then(() => {
        localStorage.clear();
        window.location.href = "/";
      });
    } else {
    }
  }

}
  

    return (
        <>
        
        <Modal show={props.show} onHide={props.handleClose} centered className="connect-wallet-box">
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={require("../assets/images/wallet-not-connected.png")} alt="wallet" style={{width: "100px"}} />
                    <h3>Wallet Not Connected</h3>
                    <p>You are not currently connected to the wallet. Please connect.</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="unset" onClick={testWeb3Auth}>
                        Connect Wallet
                    </Button>
                </Modal.Footer>
            </Modal></>
    );
};
export default NotConnectWalletPopup;