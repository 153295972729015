import { Button, Container, Dropdown, Form, Modal, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { checkProvider } from "../common/checkProvider";
import { Handleconnect, formatAddress, formatBalance, formatChainAsNum } from "../common/connectWallet";
import { useEffect, useRef, useState } from "react"
import { Link, useLocation, matchPath, useParams } from "react-router-dom";
import { setWalletAddress, setwalletConnected, setwalletBalance, setWeb3AuthProvider } from '../store/index_slice'
import { useSelector, useDispatch } from 'react-redux'
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import swal from "sweetalert";
import { LinkContainer } from 'react-router-bootstrap'
import { all } from "axios";
import { tokenAbi } from "../config/tokenAbi";
import { tokenAddress, clientId, chainConfig, web3auth } from "../config/config";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS, WEB3AUTH_NETWORK } from "@web3auth/base";
import { ethers } from "ethers";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { useNavigate } from "react-router-dom";
import RPC from "./web3RPC";
import { apiService } from "../service/api.service";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { getPublicCompressed } from "@toruslabs/eccrypto";

const { Web3 } = require('web3');


// const privateKeyProvider = new EthereumPrivateKeyProvider({
//   config: { chainConfig },
// });


// const web3auth = new Web3Auth({
//   clientId,
//   web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
//   privateKeyProvider,
// });
const Header = () => {
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState();
  const [chain, setChain] = useState()
  const [account, setAccount] = useState()
  const accountRef = useRef(0)
  const [balance, setBalance] = useState()

  const [isInitialized, setIsInitialized] = useState(false);  // Track initialization state
  const [provider, setProvider] = useState(false);
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("isLoggedIn") || false);

  const walletAddress = useSelector((state) => state.reducer.walletAddress)
  const walletBalance = useSelector((state) => state.reducer.walletBalance)
  const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
  const [dataProvider, setDataProvider] = useState('');
  const dataProviders = [{ value: "", label: "Data Providers" }, { value: "artemis", label: "Artemis" }, { value: "coinglass", label: "CoinGlass" }, { value: "coinmarketcap", label: "CoinMarketCap" }, { value: "dappradar", label: "DappRadar" }, { value: "defillama", label: "DefiLlama" }, { value: "intotheblock", label: "IntoTheBlock" }, { value: "lunarcrush", label: "LunarCrush" }, { value: "twitter", label: "Twitter" }, { value: "uncategorized", label: "Uncategorized" }];
  const [showconnect, setShowconnect] = useState(false)
  const navigate = useNavigate()
  const handleCloseConnect = () => {
    setShowconnect(false)
    window.location.reload()
  }
  const handleShowConnect = () => setShowconnect(true);
  const location = useLocation()

  const isHomeRoute = matchPath({ path: "/", end: true }, location.pathname) ||
    matchPath({ path: "/:dataProvider", end: true }, location.pathname);

  const params = useParams();

  useEffect(() => {
    const dataProviderParam = params.dataProvider;
    if (dataProviderParam) {
      setDataProvider(dataProviderParam);
    }
  }, []);


  useEffect(() => {
    // checkConnection()
    // setHasProvider(checkProvider())
    if (connected_wallet) {
      // let walletbalance = getbalance(connected_wallet)
    }
    formatChainAsNum()

  }, [])



  //   const iswallet = useSelector((state)=>state.reducer.walletConnected)
  try {
    window.ethereum.on('accountsChanged', async () => {
      // connectWallet()
    })
    window.ethereum.on('disconnect', async () => {
      dispatch(setwalletConnected(false))
      dispatch(setWalletAddress({ accounts: [] }))

      // location.clear()

    });
  } catch (error) {
    if (error == "ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider.") {
      swal("warning", "Please Install Metamask", "warning")
    }
  }

  const [hasProvider, setHasProvider] = useState(false)
  const [balanceUser, setbalanceUser] = useState(0)
  const [iswallet, setiswallet] = useState(localStorage.getItem("is_walletConnected"))
  const [connected_wallet, setconnected_wallet] = useState(localStorage.getItem("connected_wallet"))
  const disconnect = () => {
    dispatch(setwalletConnected(false))
    dispatch(setWalletAddress({ accounts: [] }))
    setconnected_wallet()
    setiswallet()
    localStorage.clear()
    swal("Success", "Wallet disconnected successfully", "success").then(() => {
      localStorage.clear();
      window.location.reload()
    })
  }
  const connectWallet = async () => {

    let accounts = await Handleconnect()
    console.log("====accounts====", accounts);
    if (accounts == "meta_error") {
      // handleClose()
    } else {
      if (accounts) {
        dispatch(setwalletConnected(true))
        dispatch(setWalletAddress(accounts))
        handleShowConnect()
        // setShow(false)
      }
    }

  }
  const checkConnection = () => {
    const webb3 = new Web3(window.ethereum);
    webb3.eth.getAccounts()
      .then(async (addr) => {
        if (addr.length == 0) {
          dispatch(setwalletConnected(true))
          dispatch(setWalletAddress(addr))
          localStorage.clear()
        }

      });
  }

  const getbalance = async (walletAddress) => {
    try {
      let webb3 = new Web3(window.ethereum);
      if (localStorage.getItem("user_type") == "CUSTODIAL") {
        webb3 = new Web3(web3AuthProvider);

      }
      let toContract = new webb3.eth.Contract(tokenAbi, tokenAddress);
      let userBalance = await toContract.methods.balanceOf(walletAddress).call();
      let balance = formatBalance(userBalance.toString())
      dispatch(setwalletBalance(balance))
      setbalanceUser(balance)
    } catch (err) {
      console.log("err>>>>>", err);
    }
  }

  const findDataProviderLabel = (value) => {
    const provider = dataProviders.find(provider => provider.value === value);
    return provider ? provider.label : null;
  };

  const [showFolio, setShowFolio] = useState(false);

  const handleCloseFolio = () => setShowFolio(false);
  const handleShowFolio = () => setShowFolio(true);

  const [showLogin, setShowLogin] = useState(false);

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);



  useEffect(() => {
    const init = async () => {
      try {
        const metamaskAdapter = new MetamaskAdapter();

        // it will add/update  the metamask adapter in to web3auth class
        web3auth.configureAdapter(metamaskAdapter);
        // await web3auth.initModal();
        function getLoginMethod(method,showOnModal=false) {
          return {
            [method]: {
              name: method,
              showOnModal,
            }
          };

        }

        await web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.AUTH]: {
              label: "auth",
              loginMethods: {
                ...getLoginMethod("facebook"),
                ...getLoginMethod("discord"),
                ...getLoginMethod("reddit"),
                ...getLoginMethod("reddit"),
                ...getLoginMethod("apple"),
                ...getLoginMethod("twitch"),
                ...getLoginMethod("line"),
                ...getLoginMethod("github"),
                ...getLoginMethod("kakao"),
                ...getLoginMethod("linkedin"),
                ...getLoginMethod("twitter"),
                ...getLoginMethod("weibo"),
                ...getLoginMethod("wechat"),
                ...getLoginMethod("farcaster"),
                ...getLoginMethod("passkeys"),
                ...getLoginMethod("authenticator"),
              },
            },
          },
        });
        dispatch(setWeb3AuthProvider(web3auth.provider));
        setProvider(web3auth.provider);
        console.log("=======web3auth.provider=====", web3auth.provider)
        if (web3auth.connected) {
          setLoggedIn(true);
          // await getBalance();
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (web3auth.connected) {

      setLoggedIn(true);
      setconnected_wallet(localStorage.getItem("connected_wallet") ? localStorage.getItem("connected_wallet") : account);
      getUserInfo();
      getAccounts();
      getBalance();
      getbalance(localStorage.getItem("connected_wallet") ? localStorage.getItem("connected_wallet") : account);
      getChainId();
    }
  }, [web3auth?.status, walletAddress?.[0], connected_wallet, account]);

  /* logout */

  const logout = async () => {
    await web3auth.logout();
    localStorage.removeItem("isLoggedIn");
    setProvider(false);
    setLoggedIn(false);
    localStorage.clear()
    swal("Success", "Wallet disconnected successfully", "success").then(() => {
      localStorage.clear();
      //  window.location.href = "/"
      window.location.reload()
    })
   

  };

  /* login with web3auth */
  const testWeb3Auth = async () => {
    if (web3auth) {
      try {
        console.log("Connecting to Web3Auth...");
        let provider = await web3auth.connect();
        dispatch(setWeb3AuthProvider(provider)); // Set global provider in Redux
        console.log("Web3Auth connected successfully!");
        let account = await getAccounts();
        await getUserInfo();
        await getChainId();
        await getBalance();
        console.log("account:::::::", account);
        // if(accountRef.current >0){
        await Web3AuthLogin(accountRef.current)

        // }

        // setLoggedIn(true); // Set local state
      } catch (error) {
        console.error("Error during Web3Auth connection:", error);
      }
    } else {
      console.log("Web3Auth not initialized yet.");
    }
  };


  const getUserInfo = async () => {
    const user = await web3auth.getUserInfo(); // Fetch user info from Web3Auth
    setUserDetail(user)
  };

  const getChainId = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const chainId = await rpc.getChainId();
    setChain(chainId)

  };

  const getAccounts = async () => {
    if (!web3auth) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(web3auth.provider);
    const address = await rpc.getAccounts();
    console.log("address:::", address);
    dispatch(setwalletConnected(true))
    dispatch(setWalletAddress(address))
    // handleShowConnect()
    setAccount(address) // Store address
    accountRef.current = address
    // if(account){
    //   console.log("account",account)
    //   await Web3AuthLogin(account)
    // }

  };

  const getBalance = async () => {
    try {
      if (!provider) {
        console.log("Provider not initialized yet");
        return;
      }

      console.log("Provider initialized:", provider);

      const rpc = new RPC(provider);

      // Fetch balance from the RPC
      const balance = await rpc?.getBalance();
      setBalance(balance);

    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  // const logout = () => {
  //   web3auth.logout(); // Logout Web3Auth
  //   setIsLoggedIn(false); // Reset login state
  //   localStorage.removeItem("isLoggedIn"); // Remove login state from localStorage
  // };

  /* login with web3auth */

  async function Web3AuthLogin(walletddress) {
    try {
      let data = {
        wallet_address: walletddress?.toLowerCase(),
        user_type: "CUSTODIAL"
      }
      const response = await apiService.loginWithWeb3(data);
      if (response.status == 200) {

        console.log("login>>>>>>>>>>>", response.data);
        localStorage.setItem("connected_wallet", walletddress)
        localStorage.setItem("is_walletConnected", true)
        localStorage.setItem("loginType", "Web3Auth")
        localStorage.setItem("user_type", "CUSTODIAL")
        localStorage.setItem("token", response.data.data.token)
        // await getBalance();
        await getChainId();
        await getUserInfo();
        window.location.reload();
      }
    } catch (error) {
      console.log("=====error==", error)
      //   setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
      }
    }

  }
 



  return (
    <>
      <div className="header">
        <ConnectWalletPopup show={showconnect} handleClose={handleCloseConnect} connectWallet={connectWallet} />
        <Navbar expand="lg" >
          <Container>
            <Navbar.Brand href="/"><img src={require("../assets/images/logo.png")} alt="logo" /></Navbar.Brand>
            <Nav className="ms-auto notification-mob">
              <Nav.Link href="" className="notification-icon" onClick={handleShowFolio}>
                <i className="fa fa-bell" aria-hidden="true"></i>
                <span className="dot-icon"></span>
              </Nav.Link>
            </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link href="/" className={(location.pathname == "/") && " active"}>Home</Nav.Link>
                <Nav.Link href="/about-us" className={(location.pathname == "/about-us") && " active"}>About us</Nav.Link>
                <Nav.Link href="/contact-form" className={(location.pathname == "/contact-us") && " active"}>Contact us </Nav.Link>
                <Nav.Link href="/redeem" className={(location.pathname == "/redeem") && " active"}>Redeem FOLIO</Nav.Link>

                <NavDropdown title="Leaderboard" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/leaderboard" className={(location?.pathname == "/leaderboard") && " active"}>Points Program</NavDropdown.Item>
                  <NavDropdown.Item href="/referrals">Referrals</NavDropdown.Item>
                </NavDropdown>




                {isHomeRoute && findDataProviderLabel(dataProvider) ?
                  <NavDropdown title={dataProvider ? findDataProviderLabel(dataProvider) : "Data Providers"} id="basic-nav-dropdown">

                    {dataProviders.map((data, index) => {
                      return (
                        <NavDropdown.Item href={"/" + data.value} > {data.label}</NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  :
                  null
                }


                {!loggedIn ? (
                  <Button variant="primary" onClick={testWeb3Auth} className="login-btn-top">
                    Login
                  </Button>
                ) : (
                  <div className="connect-wallet-btn">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {formatAddress(connected_wallet ? connected_wallet : walletAddress[0])}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item className="disabled">
                          <img src={require("../assets/images/usd-coin.png")} alt="img" />
                          {walletBalance ? walletBalance : balanceUser}
                        </Dropdown.Item>
                        <></>
                        
                       { 
                       localStorage.getItem("Web3Auth-cachedAdapter") =="metamask" ?
                       null :

                       <Dropdown.Item
                          onClick={() => {
                            const userType = localStorage.getItem("Web3Auth-cachedAdapter");
                            console.log("userType:::", userType);

    // Allow navigation only if user type is "CUSTODIAL"
    if (userType != "metamask") {
                            if (location.pathname === "/profileDetail") {
                              return;
                            }
                            else {
                              console.log("userDetail:::", userDetail, account, balance);
                              navigate("/profileDetail"
                                ,
                                { state: { userDetail: userDetail, account: account, balance } }
                              );
                            }
                          }
                          }}
                        >
                          <img src={require("../assets/images/myprofiledetail_icon.png")} />
                          ProfileDetail
                        </Dropdown.Item>}
                        <Dropdown.Item
                          href={location.pathname === "/transaction-management" ? "javascript:void(0)" : "/transaction-management"}
                        >
                          <img src={require("../assets/images/transaction.svg").default} alt="transaction" />
                          Transactions
                        </Dropdown.Item>
                        <Dropdown.Item
                          href=""
                          onClick={() => {
                            const loginType = localStorage.getItem("loginType");
                            if (loginType === "Web3Auth") {
                              logout(); // Call logout function
                            } else {
                              disconnect(); // Call disconnect function
                            }
                          }}
                        >
                          <img src={require("../assets/images/disconnect.png")} alt="disconnect" />
                          Disconnect
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                


                <Nav.Link href="" className="notification-icon web-notification" onClick={handleShowFolio}>
                  <i className="fa fa-bell" aria-hidden="true"></i>
                  <span className="dot-icon"></span>
                </Nav.Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>



      {/* folio-modal */}
      <Modal show={showFolio} onHide={handleCloseFolio} centered className="folio-popup">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p>Place your first bet to claim a 100 FOLIO welcome bonus! Redeem <a href="/redeem" target="_blank">FOLIO</a> for free NFTs to play cash prize games.</p>
        </Modal.Body>
      </Modal>
    </>
  )
};
export default Header;