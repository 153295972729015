
import { CHAIN_NAMESPACES,  WEB3AUTH_NETWORK } from "@web3auth/base";
import { ethers } from "ethers";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { useEffect, useState } from "react";

import Web3 from "web3";

const clientId = "BPi5PB_UiIZ-cPz1GtV5i1I2iOSOHuimiXBI0e-Oe_u6X3oVAbCiAZOTEBtTXw4tsluTITPqA8zMsfxIKMjiqNQ"; // get from https://dashboard.web3auth.io

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0xaa36a7",
  rpcTarget: "https://rpc.ankr.com/eth_sepolia",
  // Avoid using public rpcTarget in production.
  // Use services like Infura, Quicknode etc
  displayName: "Ethereum Sepolia Testnet",
  blockExplorerUrl: "https://sepolia.etherscan.io",
  ticker: "ETH",
  tickerName: "Ethereum",
  logo: "https://cryptologos.cc/logos/ethereum-eth-logo.png",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});


const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider,
});



const Web3AuthComponent1 = () => {
  const [isInitialized, setIsInitialized] = useState(false);  // Track initialization state
  const [provider, setProvider] = useState(false);
  const [web3provider, setWeb3provider] = useState();

  const [loggedIn, setLoggedIn] = useState(false);


  const clientId = "BPCOyAS4UhOw8U9SqwDkyAuLDctd632dLtFLEg94WDsDNUhlhyHrErO_SC1xJwso2tzIqV3gZHhw93Sel6CEUtQ"; // Web3Auth clientId

  useEffect(() => {
    const init = async () => {
      try {
        await web3auth.initModal();
        setProvider(web3auth.provider);

        if (web3auth.connected) {
          setLoggedIn(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  console.log("web3auth",web3auth);


  const login = async () => {
    const web3authProvider = await web3auth.connect();
    setProvider(web3authProvider);
    if (web3auth.connected) {
      setLoggedIn(true);
    }
  };

  const getUserInfo = async () => {
    const user = await web3auth.getUserInfo();
    uiConsole(user);
  };

  const logout = async () => {
    await web3auth.logout();
    setProvider(null);
    setLoggedIn(false);
    uiConsole("logged out");
  };


  const testWeb3Auth = async () => {
    if (web3auth) {
      try {
        console.log("Connecting to Web3Auth...");
       let provider =  await web3auth.connect();  // Trigger Web3Auth connection
       setWeb3provider(provider)
       const ethersProvider = new Web3(provider);

       const address = await ethersProvider.eth.getAccounts();
       const balance = ethersProvider.utils.fromWei(await ethersProvider.eth.getBalance(address[0]), "ether");
       uiConsole(balance);
        console.log("Web3Auth connected successfully!",balance);
        console.log("Web3Auth address!",address);
        uiConsole(address);





        // getBalance(web3)
        getUserInfo()


       






      } catch (error) {
        console.error("Error during Web3Auth connection:", error);
      }
    } else {
      console.log("Web3Auth not initialized yet.");
    }
  };


  const signTransaction = async()=>{
    
    const ethersProvider = new ethers.providers.Web3Provider(web3provider);
    const signer = await ethersProvider.getSigner();
    const transaction = {
     to: "0x6227019BE1442C55F652957219A291d2c95A8153", // recipient's address
     value: ethers.utils.parseEther("0.02"), // amount in ETH
     gasLimit: 21000, // set a gas limit for the transaction
     maxFeePerGas: ethers.utils.parseUnits('100', 'gwei'), // maximum gas price
     maxPriorityFeePerGas: ethers.utils.parseUnits('2', 'gwei') // priority fee
   };
   
   // Sign and send the transaction
   const txResponse = await signer.sendTransaction(transaction);
   console.log("Transaction Response:", txResponse);
   
   // Wait for the transaction to be mined
   const receipt = await txResponse.wait();
   console.log("Transaction Receipt:", receipt);

  }
  const getBalance = async (web3) => {
 
    
    const address = (await web3auth.getAccounts())[0];
    const balance = web3.utils.fromWei(await web3.eth.getBalance(address), "ether");
    uiConsole(balance);
  };

  function uiConsole(...args) {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
      console.log(...args);
    }
  }
  

  return (
    <div>
      <h1>Web3Auth Sapphire Devnet</h1>
      {/* {!isInitialized ? (
        <p>Initializing Web3Auth...</p>
      ) : ( */}
        <button onClick={testWeb3Auth}>Connect to Web3Auth</button>
        <div id="console" style={{ whiteSpace: "pre-line" }}>
        <p style={{ whiteSpace: "pre-line" }}></p>
      </div>
      <button onClick={signTransaction} className="card">
            Send Transaction
          </button>
      <button onClick={logout} className="card">
            Log Out
          </button>


      {/* )} */}
    </div>
  );
};

export default Web3AuthComponent1;
