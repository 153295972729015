import React,{useState} from 'react'
import {checkProvider} from "./checkProvider"
import Web3 from 'web3'
import swal from "sweetalert";
import {apiService} from "../service/api.service"
import ConnectWalletPopup from "../common/ConnectWalletPopup";
import {httpproviderPOLY,gasFee,divideBy,metamaskUrl} from "../config/config"
export async function Handleconnect() { 

    //  
               /* New */
           try{ 

            let accounts = await window.ethereum.request({   /* New */
            method: "eth_requestAccounts",                 /* New */
            })  
            console.log("====accounts==>>>>>",accounts)
           if(accounts.length>0){
            await login(accounts[0])
            return accounts 
           
           }else{
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              window.location.href = metamaskUrl
            }
           }
            // swal({ text: "Wallet Conneted", button: "OK" }).then(() => {
              
            // });
             
           }catch(error) {
            if(error=="ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider."){
              swal("warning","Please Install Metamask","warning")
              return "meta_error"
            }
            if(error=="TypeError: window.ethereum is undefined"){
              swal("warning","Please Install Metamask","warning")
              return "meta_error"
            }
           if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              window.location.href = metamaskUrl
            }

           }
          
                  
         
           /* New */
//   
                    
} 
async function login (walletddress){
  try {
    let data={
      wallet_address:walletddress
    }
    const response = await apiService.login(data);
     if (response.status == 200) {


        console.log("login>>>>>>>>>>>",response.data);
        localStorage.setItem("connected_wallet",walletddress)
        localStorage.setItem("is_walletConnected",true)
        localStorage.setItem("token",response.data.data.token)
       // setLoader(false);
     }
   } catch (error) {
    console.log("=====error==",error)
   //   setLoader(false);
     if (error?.response?.status == 401) {
       swal({ text: "Unauthorized", button: "OK" }).then(() => {
         localStorage.clear();
         window.location.href = "/";
       });
     } else {
     }
   }
 
}
export const formatAddress = (addr = "") => {
    
    console.log("====addr====",typeof addr, "sss", addr);
    let address = '';
    if(addr !== "undefined" && addr !== "null" && addr !== "" && addr !== undefined && addr !== null){
       address = addr.substring(0,6)+"......"+addr.substring(addr.length-6);
    }

    return address;
  }
  export const formatBalance = (rawBalance) => {
    // console.log((rawBalance/ 1000000000000000000))
    const balance = (rawBalance / divideBy).toFixed(2)
    return balance
  }
  const web3 = new Web3(window.ethereum)
  export const formatChainAsNum = async() => {
    try{
    const currentChainId = await web3.eth.net.getId()
    return parseInt(currentChainId);
  } catch (error) {
    console.log("=========error=====",error)
    if(error=="ProviderError: Provider not available. Use `.setProvider` or `.provider=` to initialize the provider."){
      swal("warning","Please Install Metamask","warning")
    }
    //   setLoader(false);
   
    }
  }

  export const  formatNumber = (num,decimals=6) => {
    let formattedNumber = num.toFixed(decimals);
    // Remove trailing zeros and decimal point if all zeros
    formattedNumber = formattedNumber.replace(/\.?0*$/, '');
    return formattedNumber;
  }

  export const formatBalanceWithDecimals = (rawBalance,decimals) => {
    // console.log("formatBalanceWithDecimals",rawBalance, decimals);
    decimals = decimals ? decimals : 6
    const balance = (rawBalance / (10 ** decimals))
    return formatNumber(balance,decimals);
  }


  //   
  
//   const chainId = await window.ethereum.request({
//     method: 'eth_chainId',
//   })


export const getEstimateGasFee = async (props) => {
    console.log("getEstimateGasFee---->props---->", props);
    let web3;
    let httpProvider =  httpproviderPOLY
    if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
        web3 = new Web3(window.ethereum);
    } else {
        web3 = new Web3(httpProvider);
    }
    console.log("getEstimateGasFee---->prop props.paramss---->",  props.params);
    const params = props.params;
    const methodName = props.methodName;
    const sendParams = props.sendParams;
    let gasLimit, balance, totalGas, gasPriceGet;
    try {

        let provider = await web3.setProvider(httpProvider);
        gasPriceGet = await web3.eth.getGasPrice();
        console.log("gasPriceGet---->prop props.paramss---->",  gasPriceGet);
        let contractMethods = props.contractMethods;
        console.log("methodName",methodName,params,sendParams)
        gasLimit =  await contractMethods.methods[methodName](...params).estimateGas(sendParams).then((res) => { return res; }) 
        console.log("gasLimit---->prop props.paramss---->",  gasLimit);
    } catch (error) {
        gasLimit = gasFee;
        console.log("gas fee calculation error-------->", error);
    }

    gasLimit = Math.ceil(Number(gasLimit) * 1.1);
    console.log("gasLimit---->1.1---->",  gasLimit);
    gasPriceGet = Math.ceil(Number(gasPriceGet) * 1.1);
    console.log("gas Price------>", gasPriceGet, "gas--Limit---->", gasLimit);
    return [gasPriceGet, gasLimit, (balance > totalGas ? true : false)];
};