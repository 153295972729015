import { Col, Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { chainId, folioContractAddress, formatWalletAddress, tokenAddress, web3auth } from "../config/config";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import Web3 from "web3";
import { tokenAbi } from "../config/tokenAbi";
import { useSelector } from "react-redux";

const ProfileDetail = () => {
    const [loader, setLoader] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const location = useLocation();
    const { userDetail, account, balance } = location?.state || {};
    const [copySuccess, setCopySuccess] = useState("");
    const [privateKeySuccess, setPrivatekeySuccess] = useState("");
    const [privateKey, setPrivateKey] = useState(null);
    const [folioBalance, setFolioBalance] = useState("0.00");
    const walletAddress = localStorage?.getItem("connected_wallet") || account;
    const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);

    const copyToClipboard = (text, setSuccessCallback) => {
        if (text) {
            navigator.clipboard.writeText(text).then(() => {
                setSuccessCallback("Copied!");
                setTimeout(() => setSuccessCallback(""), 2000);
            }).catch(err => console.error("Failed to copy: ", err));
        }
    };

    useEffect(() => {
        const userType = localStorage.getItem("Web3Auth-cachedAdapter");
        if (userType === "metamask") {
            window.location.href = "/";
        }
    }, []);

    const exportPrivateKey = async () => {
        try {
            const appScopedPrivKey = await web3auth?.provider?.request({
                method: "eth_private_key",
            });
            const appPubKey = getPublicCompressed(
                Buffer.from(appScopedPrivKey.padStart(64, "0"), "hex")
            ).toString("hex");
            setPrivateKey(appScopedPrivKey);
            console.log("Private Key:", appScopedPrivKey);
            console.log("Public Key:", appPubKey);
        } catch (error) {
            console.error("Failed to export private key:", error);
        }
    };

    const getFolioBalance = async () => {
        try {
            let web3 = new Web3(web3AuthProvider);
            let folioContract = new web3.eth.Contract(tokenAbi, folioContractAddress);
            if (localStorage.getItem("user_type") === "CUSTODIAL") {
                const balance = await folioContract.methods.balanceOf(walletAddress).call();
                let decimals = await folioContract.methods.decimals().call();
                let res= Number(balance) / 10 ** Number(decimals);
                console.log("getFolioBalance Balance:", res);
                // const formattedBalance = web3.utils.fromWei(balance, "ether");
                setFolioBalance(res); // agar balance null ya empty ho, to 0.00 dikhaye
            }
        } catch (error) {
            console.error("Error fetching balance:", error);
            setFolioBalance("0.00"); // error case mein bhi 0.00 dikhao
        }
    };
    

    const [usdcBalance, setUsdcBalance] = useState("0.00"); // State to store USDC balance

const getUSDCBalance = async () => {
    try {
        let web3 = new Web3(web3AuthProvider); // Assuming MetaMask or a similar provider
        let usdcContract = new web3.eth.Contract(tokenAbi, tokenAddress);
        let balance = await usdcContract.methods.balanceOf(walletAddress).call();
        let decimals = await usdcContract.methods.decimals().call();
        let res= Number(balance) / 10 ** Number(decimals);
        console.log("USDC Balance:", res);
        setUsdcBalance(parseInt(res)); // Store USDC balance in state
        return balance;
    } catch (error) {
        console.error('balance Error fetching USDC balance:', error);
    }
};

    useEffect(() => {
        if (web3AuthProvider) {
            getFolioBalance();  // Fetch FOLIO balance on component mount
            getUSDCBalance()
        }
    }, [walletAddress,web3AuthProvider]);

    return (
        <>
            <div className="ads-banner-top" id="ads-banner-top"></div>
            <div id="ads-home-right"></div>
            <div id="ads-home-left"></div>
            <section className="profile-detail-area profile-details-section">
                <Container>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="justify-content-center">
                            <Col md={12} lg={12}>
                                <div className="profile-top-heading mb-3">
                                    <h2 className="heading">Profile Details</h2>
                                </div>
                                <h4 className="sub-heading">User Information</h4>
                            </Col>
                        </Row>
                        <Row className="justify-content-left">
                            <Col md={6} lg={6}>
                                <div className="user-detail-box profile-details-content">
                                    <div className="under-profile-box">
                                        <p className="label-text">Wallet Address:</p>
                                        <p className="wallet-details-text">
                                            {formatWalletAddress(walletAddress)}  
                                            <i
                                                className="fa fa-copy"
                                                onClick={() => copyToClipboard(walletAddress, setCopySuccess)}
                                                style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
                                                title="Copy Wallet Address"
                                            ></i>
                                            {copySuccess && <span style={{ color: "green", marginLeft: "10px", fontSize: "12px" }}>{copySuccess}</span>}
                                        </p>
                                    </div>
                                </div>
                                <div className="under-profile-box">
                                    <p className="label-text">Email or Phone:</p>
                                    <p className="wallet-details-text">{userDetail?.verifierId || "-"}</p>
                                </div>
                                <div className="under-profile-box">
                                    <p className="label-text">Balance:</p>
                                    <p className="wallet-details-text">{balance || "0.00"} MATIC</p>
                                    <div className="d-flex">
                                        <div className="another-balacne w-50">
                                            <p className="wallet-details-text">{usdcBalance} <span className="last-text">USDC</span></p>
                                        </div>
                                        <div className="another-balacne w-50">
                                            <p className="wallet-details-text">{folioBalance} <span className="last-text">FOLIO</span></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} lg={6}>
                                <div className="export-section">
                                    <button onClick={exportPrivateKey} className="export-section">
                                        <img
                                            src={require("../assets/images/export-icon.png")}
                                            alt="vector"
                                        />
                                        <span>Export Private Key</span>
                                    </button>
                                    {privateKey && (
                                        <div className="under-profile-box mt-3">
                                            <p className="label-text">Private Key:</p>
                                            <p className="wallet-details-text">
                                                {privateKey} 
                                                <i
                                                    className="fa fa-copy"
                                                    onClick={() => copyToClipboard(privateKey, setPrivatekeySuccess)}
                                                    style={{ marginLeft: "10px", cursor: "pointer", color: "blue" }}
                                                    title="Copy Private Key"
                                                ></i>
                                                {privateKeySuccess && <span style={{ color: "green", marginLeft: "10px", fontSize: "12px" }}>{privateKeySuccess}</span>}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default ProfileDetail;
