import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { contractAbi } from "../config/contractAbi";
import { contractOldAbi } from "../config/contractOldAbi";
import { tokenAbi } from "../config/tokenAbi";
import { betCounterAbi } from "../config/betCounterAbi";
import { soloCounterAbi } from "../config/soloCounterAbi";
import { multiCounterAbi } from "../config/multiCounterAbi";
import { erc721Abi } from "../config/erc721Abi";
import { soloCounterAddress, soloNft, soloExtendedNft, multiChoiceNft, multiChoiceBetCounter, soloTimeExtensionNFT_01_BETS_ADDRESS, soloTimeExtensionNFT_03_BETS_ADDRESS, soloTimeExtensionNFT_10_BETS_ADDRESS, soloTimeExtensionNFT_01_MAX_BETS, soloTimeExtensionNFT_03_MAX_BETS, soloTimeExtensionNFT_10_MAX_BETS, multiTimeExtensionNFT_01_BETS_ADDRESS, multiTimeExtensionNFT_01_MAX_BETS, multiTimeExtensionNFT_20_BETS_ADDRESS, multiTimeExtensionNFT_20_MAX_BETS } from "../config/config"
import {
  betCounterAddress,
  WEB3_PROVIDER
} from "../config/config";
import SanitizeHTML from "./SanitizeHTML"
import {
  formatBalanceWithDecimals,
} from "../common/connectWallet";
import Countdown from 'react-countdown';
import moment from 'moment';
import { useSelector } from 'react-redux';


const getWeb3Contract = (contractABI, contractAddress) => {
  const web3 = new Web3(WEB3_PROVIDER);
  return new web3.eth.Contract(contractABI, contractAddress);
};

const RiskFreeMessages = ({ prediction, is_wallet_conected, extendedNftRemaning }) => {
  const [erc20TokenBalance, setErc20TokenBalance] = useState(null);
  const [erc721Balance, setErc721Balance] = useState(null);
  const [requiredBetFolioNft, setRequiredBetFolioNft] = useState(false);
  const [mintedNft, setMintedNft] = useState(0);
  const [soloBalance, setSoloBalance] = useState()
  const [multiBalance, setMultiBalance] = useState()
  const [soloExtendedBalance, setSoloExtendedBalance] = useState()
  const [soloUserBets, setSoloUserBets] = useState()
  const [soloRemaining, setSoloRemaining] = useState();
  const [soloExtendedRemaining, setSoloExtendedRemaining] = useState()
  const [betCounterNftUserDetail, setBetCounterNftUserDetail] = useState()
  const web3AuthProvider = useSelector((state) => state.reducer.web3AuthProvider);
  const [totalExtensionNFTBalance, setTotalExtensionNFTBalance] = useState(0)
  const extensionType = window.location.pathname.split("/")[1];


  useEffect(() => {
    if (web3AuthProvider) {
      extensionNFTBalances();
    }
  }, [web3AuthProvider]);

  const extensionNFTBalances = async () => {
    // console.log("pathname------------------->",pathname.split("/")[1]);
    const web3 = new Web3(web3AuthProvider);
    const wallet = localStorage.getItem("connected_wallet");
    let totalExtensionNFT = 0;
    if (extensionType == "solo-predictions") {
      const soloTimeExtensionNFT_01_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_01_BETS_ADDRESS);
      const extensionNFT01_BETSBalance = await soloTimeExtensionNFT_01_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT = Number(extensionNFT01_BETSBalance) * soloTimeExtensionNFT_01_MAX_BETS;
      const soloTimeExtensionNFT_03_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_03_BETS_ADDRESS);
      const extensionNFT03_BETSBalance = await soloTimeExtensionNFT_03_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT03_BETSBalance) * soloTimeExtensionNFT_03_MAX_BETS;
      const soloTimeExtensionNFT_10_BETSContract = new web3.eth.Contract(tokenAbi, soloTimeExtensionNFT_10_BETS_ADDRESS);
      const extensionNFT10_BETSBalance = await soloTimeExtensionNFT_10_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT10_BETSBalance) * soloTimeExtensionNFT_10_MAX_BETS;
    } else if (extensionType == "multi-choice-predictions") {
      const multiTimeExtensionNFT_01_BETSContract = new web3.eth.Contract(tokenAbi, multiTimeExtensionNFT_01_BETS_ADDRESS);
      const extensionNFT01_BETSBalance = await multiTimeExtensionNFT_01_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT = Number(extensionNFT01_BETSBalance) * multiTimeExtensionNFT_01_MAX_BETS;

      const multiTimeExtensionNFT_20_BETSContract = new web3.eth.Contract(tokenAbi, multiTimeExtensionNFT_20_BETS_ADDRESS);
      const extensionNFT20_BETSBalance = await multiTimeExtensionNFT_20_BETSContract._methods.balanceOf(wallet).call()
      totalExtensionNFT += Number(extensionNFT20_BETSBalance) * multiTimeExtensionNFT_20_MAX_BETS;
    }
    console.log("totalExtensionNFT", totalExtensionNFT);
    setTotalExtensionNFTBalance(totalExtensionNFT)
  }

  useEffect(() => {
    if (!prediction) return;
    if (!is_wallet_conected) return;


    const fetchBalances = async () => {
      console.log("fetchBalances>>>>>>>")
      const wallet = localStorage.getItem("connected_wallet");
      const { access_setting, access_setting_token_address, nft_contract_address } = prediction;
      console.log("RiskFreeMessages access_setting, access_setting_token_address, nft_contract_address", access_setting, access_setting_token_address, nft_contract_address);
      try {
        if ([1, 3, 4].includes(access_setting)) {
          console.log("betCounterAddress----", betCounterAddress);
          const betCounterContract = getWeb3Contract(betCounterAbi, betCounterAddress);
          const canBet = await betCounterContract.methods.canBetOnRiskFree(wallet).call();
          console.log("canbet>>>>>>>>>>>>>", canBet);
          setRequiredBetFolioNft(!canBet);
          if (!canBet) {
            const nftMinted = await betCounterContract.methods.userBets(wallet).call();
            console.log("nftMinted", nftMinted)
            setBetCounterNftUserDetail(nftMinted);
            setMintedNft(parseInt(nftMinted.numberOfNftMinted));
            console.log("nftMinted>>>>>>>>>>>", nftMinted)
          } else {
            const nftMinted = await betCounterContract.methods.userBets(wallet).call();
            console.log("nftMinted", nftMinted)
            console.log("nftMinted>>>>>>>>>>>", nftMinted)
            setBetCounterNftUserDetail(nftMinted);
          }
        }
        console.log("erc721Contract", access_setting);
        if ([3, 5].includes(access_setting)) {
          console.log("erc721Contract");
          const erc721Contract = getWeb3Contract(erc721Abi, nft_contract_address);
          console.log("erc721Contract 1");
          const balance = await erc721Contract.methods.balanceOf(wallet).call();
          console.log("erc721Contract 2");
          console.log("RiskFreeMessages erc721Contract", balance)
          setErc721Balance(parseInt(balance));
        }

        if ([4, 6].includes(access_setting)) {
          const erc20Contract = getWeb3Contract(tokenAbi, access_setting_token_address);
          console.log("RiskFreeMessages methods", erc20Contract.methods);
          const balance = await erc20Contract.methods.balanceOf(wallet).call();
          console.log("RiskFreeMessages  balance erc20>>>", balance, wallet, access_setting_token_address);
          setErc20TokenBalance(parseInt(balance));

        }


        if ([7].includes(access_setting)) {
          console.log("solo prediction>>>>>>>");
          const soloNftContract = getWeb3Contract(erc721Abi, soloNft);
          console.log("soloNftContract 1");
          const soloBalanceGet = await soloNftContract.methods.balanceOf(wallet).call();
          console.log("soloNftContract 2");
          console.log("tushar ----- user solo nft balance", parseInt(soloBalanceGet))
          setSoloBalance(parseInt(soloBalanceGet));

          console.log("solo prediction Extended >>>>>>>");
          const soloExtendedNftContract = getWeb3Contract(erc721Abi, soloExtendedNft);
          console.log("soloNftContract  Extended1");
          const soloExtendedbalanceGet = await soloExtendedNftContract.methods.balanceOf(wallet).call();
          console.log("soloNftContract Extended 2");
          console.log(" Extended nft balance", parseInt(soloExtendedbalanceGet))
          console.log("tushar solo RiskFreeMessages Extended soloNftContract", soloExtendedbalanceGet)
          setSoloExtendedBalance(parseInt(soloExtendedbalanceGet))

          const soloCounterContract = getWeb3Contract(soloCounterAbi, soloCounterAddress);
          const userBets = await soloCounterContract.methods.userBets(wallet).call();
          console.log("tushar userBets>>>>>>", userBets);
          console.log("soloCounterContract.methods countt", soloCounterContract.methods);
          setSoloUserBets(userBets);

          let soloRemaingChance = parseInt(userBets.soloRemaingChance);


          if (parseInt(userBets.riskFreeBetCount) == 0) {
            console.log("solo countt riskFreeBetCount is zero ")
            soloRemaingChance = 3;
          } else {
            if ((parseInt(userBets.riskFreeExpiryTime) * 1000) < (new Date().getTime()) || ((soloRemaingChance == 0) && (soloBalanceGet > (parseInt(userBets.numberOfNftMinted))))) {
              console.log("solo countt riskFreeExpiryTime is expired ");

              if (soloBalanceGet > (parseInt(userBets.numberOfNftMinted))) {
                console.log("solo countt mentioning to 3 ");
                soloRemaingChance = 3;
              }
              else {
                console.log("solo countt mentioning to 0 ");
                soloRemaingChance = 0;
              }

            }
          }
          setSoloRemaining(parseInt(soloRemaingChance));
          console.log("**************************************************");
          let extendedRemaingChance = parseInt(userBets.extendedRemaingChance);
          if (parseInt(userBets.extendedBetCount) == 0) {
            console.log("tushar solo countt extendedBetCount is zero ")
            extendedRemaingChance = 10;
          } else {
            if ((parseInt(userBets.extendedExpiryTime) * 1000) < (new Date().getTime()) || ((extendedRemaingChance == 0) && (soloExtendedbalanceGet > (parseInt(userBets.numberOfextendedNftMinted))))) {
              console.log("tushar solo countt extendedExpiryTime is expired ");
              if (soloExtendedbalanceGet > (parseInt(userBets.numberOfextendedNftMinted))) {
                console.log("solo countt numberOfextendedNftMinted mentioning to 5 ");
                extendedRemaingChance = 10;
              }
              else {
                console.log("solo countt numberOfextendedNftMinted mentioning to 0 ");
                extendedRemaingChance = 0;
              }

            }
          }
          setSoloRemaining(parseInt(soloRemaingChance));
          setSoloExtendedRemaining(parseInt(extendedRemaingChance));
        }
        // ////////////////// ////////////////// ////////////////// ////////////////// ////////////////// ////////////////// ////////////////// ////////////////// ////////////////
        if ([8, 9, 10].includes(access_setting)) {
          console.log("multioptions>>>>>>>");
          const multiNftContract = getWeb3Contract(erc721Abi, multiChoiceNft);
          console.log("multioptions 1");
          const multiBalanceGet = await multiNftContract.methods.balanceOf(wallet).call();
          console.log("multioptions 2");
          console.log("tushar ----- user solo nft balance", parseInt(multiBalanceGet))
          setMultiBalance(parseInt(multiBalanceGet));

          const multiCounterContract = getWeb3Contract(multiCounterAbi, multiChoiceBetCounter);
          const userBets = await multiCounterContract.methods.userBets(wallet).call();
          console.log("tushar userBets in multiii>>>>>>", userBets);
          console.log("multiCounterContract.methods countt", multiCounterContract.methods);
          setSoloUserBets(userBets);
          let soloRemaingChance = parseInt(userBets.soloRemaingChance);


          if (parseInt(userBets.riskFreeBetCount) == 0) {
            console.log("multiCounterContract riskFreeBetCount is zero ")
            soloRemaingChance = 20;
          } else {
            if ((parseInt(userBets.riskFreeExpiryTime) * 1000) < (new Date().getTime()) || ((soloRemaingChance == 0) && (multiBalanceGet > (parseInt(userBets.numberOfNftMinted))))) {
              console.log("solo countt riskFreeExpiryTime is expired ");

              if (multiBalanceGet > (parseInt(userBets.numberOfNftMinted))) {
                console.log("solo countt mentioning to 3 ");
                soloRemaingChance = 20;
              }
              else {
                console.log("solo countt mentioning to 0 ");
                soloRemaingChance = 0;
              }

            }
          }
          setSoloRemaining(parseInt(soloRemaingChance));
        }


      } catch (error) {
        console.error("RiskFreeMessages Blockchain interaction failed:", error);
      }
    };

    fetchBalances();
  }, [prediction]);


  const displayERC721Message = () => {
    if (requiredBetFolioNft && mintedNft > 0) {
      return "Your BetFolio NFT Pass has Expired. <a href='https://betfolio-risk-free-predictions.nfts2.me/' target='_blank' rel='noopener noreferrer' > Mint a new NFT </a> to participate in predictions that require it."
    } else if (requiredBetFolioNft) {
      return ""
    }
    return null;
  };

  let renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // setmadebid(true);
      return <span>Closed</span>;
    } else {
      return (
        <span>
          {days + "d"} {hours + "h"}:{minutes + "m"}:{seconds + "s"}
        </span>
      );
    }
  };

  return (
    <div>
      {displayERC721Message() && (
        <p className="prediction-only-view">
          <SanitizeHTML html={displayERC721Message()} />
        </p>
      )}


      {betCounterNftUserDetail && new Date((parseInt(betCounterNftUserDetail?.riskFreeExpiryTime) * 1000)) > new Date() ?
        <div className='countdown-bottom-area'>
          <p>Countdown to NFT expiry:</p>
          <Countdown
            date={moment(new Date((parseInt(betCounterNftUserDetail?.riskFreeExpiryTime) * 1000))).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            renderer={renderer}
          >
            {" "}
          </Countdown>
        </div>



        :
        null
      }

      {soloBalance > 0 &&
        <>
          <p className='extension-bets'>
            You have {soloRemaining}/3 bets remaining with your Solo Prediction Pass.
            {soloRemaining == 0 &&
              <span>
                <a href='https://betfolio-solo-prediction-pass.nfts2.me/' target='_blank' > Mint another pass </a> to continue betting.
              </span>
            }
          </p>
          {new Date((parseInt(soloUserBets?.riskFreeExpiryTime) * 1000)) > new Date() ?
            <div className='countdown-bottom-area'>
              <p>Countdown to NFT expiry:</p>
              <Countdown
                date={moment(new Date((parseInt(soloUserBets?.riskFreeExpiryTime) * 1000))).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                renderer={renderer}
              >
                {" "}
              </Countdown>
            </div>
            :
            null
          }


        </>

      }

      {soloExtendedBalance > 0 &&
        <>
          {new Date((parseInt(soloUserBets?.extendedExpiryTime) * 1000)) > new Date() ?
            <div className='countdown-bottom-area'>
              <p>Countdown to NFT expiry:</p>
              <Countdown
                date={moment(new Date((parseInt(soloUserBets?.extendedExpiryTime) * 1000))).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                renderer={renderer}
              >
                {" "}
              </Countdown>
            </div>
            :
            null
          }

          <p className='extension-bets'>
            You have {soloExtendedRemaining}/10 bets remaining with your Extended Solo Prediction Pass.
            {soloExtendedRemaining == 0 &&
              <span>
                <a href='https://betfolio-extended-solo-pass.nfts2.me/' target='_blank' > Mint another pass </a> to continue betting.”
              </span>
            }
          </p>
        </>
      }

      {multiBalance > 0 && (
        <>
          <p>
            You have {soloRemaining}/20 bets remaining with your Multi Prediction Pass.
            {soloRemaining == 0 &&
              <span>
                <a href='https://betfolio-multi-choice-pass.nfts2.me/' target='_blank' > Mint another pass </a> to continue betting.
              </span>
            }
          </p>

          {new Date((parseInt(soloUserBets?.riskFreeExpiryTime) * 1000)) > new Date() ?
            <div className='countdown-bottom-area'>
              <p>Countdown to NFT expiry:</p>
              <Countdown
                date={moment(new Date((parseInt(soloUserBets?.riskFreeExpiryTime) * 1000))).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                renderer={renderer}
              >
                {" "}
              </Countdown>
            </div>
            :
            null
          }
        </>
      )}

      {totalExtensionNFTBalance !=0 ?<p className='extension-bets'>
        You have {extendedNftRemaning}/{totalExtensionNFTBalance} {totalExtensionNFTBalance > 1 ? 'bets':'bet'} remaining with your {extensionType =="solo-predictions"? "Solo" :"Multi-Choice"} Time Extension NFT.
      </p>:""}


    </div>
  );
};

export default RiskFreeMessages;
