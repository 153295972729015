import { useEffect, useState } from "react";
import {
  Web3AuthMPCCoreKit,
  WEB3AUTH_NETWORK,
  parseToken,
  generateFactorKey,
  COREKIT_STATUS,
  keyToMnemonic,
  mnemonicToKey,
  makeEthereumSigner,
} from "@web3auth/mpc-core-kit";
import { EthereumSigningProvider } from '@web3auth/ethereum-mpc-provider';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import Web3AuthSingleFactorAuth from "@web3auth/single-factor-auth";
import { CommonPrivateKeyProvider } from '@web3auth/base-provider';

import Web3 from "web3";
import BN from "bn.js";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// import "./App.css";
import { tssLib } from "@toruslabs/tss-dkls-lib";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { Web3Auth } from "@web3auth/modal";
import { ethers } from "ethers";

const web3AuthClientId = "BCYWcW2bo6KIaogLUszOHFIfZiL1WJ25DP3-Tn5FbE-h7dfgeI_E735yf2z-fLPlGA7g1C1ZpsJtZs7Rk12Mvck";

const verifier = "w3a-sfa-web-google";
const firebaseVerifier = "w3a-firebase-demo";

// const chainConfigOld = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0x1", // Mainnet
//   rpcTarget: "https://rpc.ankr.com/eth",
//   displayName: "Ethereum Mainnet",
//   blockExplorer: "https://etherscan.io/",
//   ticker: "ETH",
//   tickerName: "Ethereum",
// };

 let chainConfig = {
    chainNamespace: "eip155",
    chainId: "0xaa36a7",
    displayName: "Ethereum Sepolia",
    tickerName: "Ethereum",
    ticker: "ETH",
    rpcTarget: "https://sepolia.infura.io/v3/4a68d146e58742f080b41b1584a86cd4",
    blockExplorerUrl: "https://sepolia.etherscan.io",
    logo: "https://web3auth.io/images/web3authlog.png",
  }

const coreKitInstance = new Web3AuthMPCCoreKit({
  web3AuthClientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.MAINNET,
  storage: window.localStorage,
  manualSync: true,
  tssLib: tssLib,
});

const evmProvider = new EthereumSigningProvider({ config: { chainConfig } });
evmProvider.setupProvider(makeEthereumSigner(coreKitInstance));

const firebaseConfig = {
  apiKey: "AIzaSyB0nd9YsPLu-tpdCrsXn8wgsWVAiYEpQ_E",
  authDomain: "web3auth-oauth-logins.firebaseapp.com",
  projectId: "web3auth-oauth-logins",
  storageBucket: "web3auth-oauth-logins.appspot.com",
  messagingSenderId: "461819774167",
  appId: "1:461819774167:web:e74addfb6cc88f3b5b9c92",
};

function WebAuth() {
  const [coreKitStatus, setCoreKitStatus] = useState(COREKIT_STATUS.NOT_INITIALIZED);
  const [backupFactorKey, setBackupFactorKey] = useState("");
  const [mnemonicFactor, setMnemonicFactor] = useState("");

  const app = initializeApp(firebaseConfig);

  useEffect(() => {
    const init = async () => {
      await coreKitInstance.init();
      setCoreKitStatus(coreKitInstance.status);
    };
    init();
  }, []);

  const login = async (credentialResponse) => {
    try {
      if (!coreKitInstance) {
        throw new Error("initiated to login");
      }
      const idToken = await credentialResponse.credential;
      const parsedToken = parseToken(idToken);

      const idTokenLoginParams = {
        verifier,
        verifierId: parsedToken.email,
        idToken,
      };

      await coreKitInstance.loginWithJWT(idTokenLoginParams);
      if (coreKitInstance.status === COREKIT_STATUS.LOGGED_IN) {
        await coreKitInstance.commitChanges(); // Needed for new accounts
      }

      if (coreKitInstance.status === COREKIT_STATUS.REQUIRED_SHARE) {
        uiConsole(
          "required more shares, please enter your backup/ device factor key, or reset account [unrecoverable once reset, please use it with caution]"
        );
      }

      setCoreKitStatus(coreKitInstance.status);
    } catch (err) {
      uiConsole(err);
    }
  };

  const inputBackupFactorKey = async () => {
    // if (!coreKitInstance) {
    //   throw new Error("coreKitInstance not found");
    // }
    // if (!backupFactorKey) {
    //   throw new Error("backupFactorKey not found");
    // }
    // const factorKey = new BN(backupFactorKey, "hex");
    // await coreKitInstance.inputFactorKey(factorKey);

    // setCoreKitStatus(coreKitInstance.status);

    // if (coreKitInstance.status === COREKIT_STATUS.REQUIRED_SHARE) {
    //   uiConsole(
    //     "required more shares even after inputing backup factor key, please enter your backup/ device factor key, or reset account [unrecoverable once reset, please use it with caution]"
    //   );
    // }
  };

  const getSocialMFAFactorKey = async () => {
    // try {
    //   const web3authSfa = new Web3AuthSingleFactorAuth({
    //     clientId: web3AuthClientId,
    //     web3AuthNetwork: WEB3AUTH_NETWORK.MAINNET,
    //     usePnPKey: false,
    //   });
    //   const privateKeyProvider = new CommonPrivateKeyProvider({ config: { chainConfig } });
    //   await web3authSfa.init(privateKeyProvider);

    //   const auth = getAuth(app);
    //   const res = await signInWithEmailAndPassword(auth, 'custom+jwt@firebase.login', 'Testing@123');
    //   const idToken = await res.user.getIdToken(true);
    //   const userInfo = parseToken(idToken);

    //   const web3authProvider = await web3authSfa.connect({
    //     verifier: firebaseVerifier,
    //     verifierId: userInfo.sub,
    //     idToken,
    //   });

    //   const factorKey = await web3authProvider.request({
    //     method: "private_key",
    //   });
    //   uiConsole("Social Factor Key: ", factorKey);
    //   setBackupFactorKey(factorKey);
    //   return factorKey;
    // } catch (err) {
    //   uiConsole(err);
    //   return "";
    // }
  };

  const enableMFA = async () => {
    if (!coreKitInstance) {
      throw new Error("coreKitInstance is not set");
    }
    try {
      const factorKey = new BN(await getSocialMFAFactorKey(), "hex");
      await coreKitInstance.enableMFA({ factorKey });

      if (coreKitInstance.status === COREKIT_STATUS.LOGGED_IN) {
        await coreKitInstance.commitChanges();
      }

      uiConsole("MFA enabled, device factor stored in local store, deleted hashed cloud key, your backup factor key is associated with the firebase email password account in the app");
    } catch (e) {
      uiConsole(e);
    }
  };

  const keyDetails = async () => {
    if (!coreKitInstance) {
      throw new Error("coreKitInstance not found");
    }
    uiConsole(coreKitInstance.getKeyDetails());
  };

  const getDeviceFactor = async () => {
    try {
      const factorKey = await coreKitInstance.getDeviceFactor();
      setBackupFactorKey(factorKey);
      uiConsole("Device share: ", factorKey);
    } catch (e) {
      uiConsole(e);
    }
  };

  const exportMnemonicFactor = async () => {
    if (!coreKitInstance) {
      throw new Error("coreKitInstance is not set");
    }
    uiConsole("export share type: ", "RECOVERY");
    const factorKey = generateFactorKey();
    await coreKitInstance.createFactor({
      shareType: "RECOVERY",
      factorKey: factorKey.private,
    });
    const factorKeyMnemonic = await keyToMnemonic(factorKey.private.toString("hex"));
    if (coreKitInstance.status === COREKIT_STATUS.LOGGED_IN) {
      await coreKitInstance.commitChanges();
    }
    uiConsole("Export factor key mnemonic: ", factorKeyMnemonic);
  };

  const MnemonicToFactorKeyHex = async (mnemonic) => {
    if (!coreKitInstance) {
      throw new Error("coreKitInstance is not set");
    }
    try {
      const factorKey = await mnemonicToKey(mnemonic);
      setBackupFactorKey(factorKey);
      return factorKey;
    } catch (error) {
      uiConsole(error);
    }
  };

  const getUserInfo = async () => {
    const user = coreKitInstance.getUserInfo();
    uiConsole(user);
  };

  const logout = async () => {
    await coreKitInstance.logout();
    setCoreKitStatus(coreKitInstance.status);
    uiConsole("logged out");
  };

  const getAccounts = async () => {
    if (!coreKitInstance) {
      uiConsole("provider not initialized yet");
      return;
    }
    const web3 = new Web3(evmProvider);
    const address = await web3.eth.getAccounts();
    uiConsole(address);
  };

  const getBalance = async () => {
    if (!coreKitInstance) {
      uiConsole("provider not initialized yet");
      return;
    }
    const web3 = new Web3(evmProvider);
    const address = (await web3.eth.getAccounts())[0];
    const balance = web3.utils.fromWei(await web3.eth.getBalance(address), "ether");
    uiConsole(balance);
  };

  const signMessage = async () => {
    if (!coreKitInstance) {
      uiConsole("provider not initialized yet");
      return;
    }
    const web3 = new Web3(evmProvider);
    const address = (await web3.eth.getAccounts())[0];
    const originalMessage = "YOUR_MESSAGE";
    const signedMessage = await web3.eth.sign(originalMessage, address);
    uiConsole(signedMessage);
  };

  const sendTransaction = async () => {
    if (!coreKitInstance) {
      uiConsole("provider not initialized yet");
      return;
    }
    const web3 = new Web3(evmProvider);
    const address = (await web3.eth.getAccounts())[0];
    const txRes = await web3.eth.sendTransaction({
      from: address,
      to: address,
      value: web3.utils.toWei("0.01"),
    });
    uiConsole(txRes);
  };

  const uiConsole = (...args) => {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
  };


  const [web3auth, setWeb3auth] = useState(null);  // Store Web3Auth instance
  const [isInitialized, setIsInitialized] = useState(false);  // Track initialization state

  const clientId = "BKE-UGBk_Mmkv1kDHLC_f38WxgOksSMibskC7jxYGuuUQHEKu8C-SwisPZ7rvw1TXxtQBSrW6_GPuDFG2qtIGpQ"; // get from https://dashboard.web3auth.io

  useEffect(() => {

    async function init() {
      try {


        // const web3authInstance = new Web3Auth({
        //   clientId, // Get your Client ID from Web3Auth Dashboard
        //   chainConfig: {
        //     chainNamespace: "eip155",
        //     chainId: "0x1",
        //   },
        //   web3AuthNetwork: "mainnet",
        //   privateKeyProvider: new ethers.providers.JsonRpcProvider("https://ethereum-rpc.publicnode.com"),  // Add your RPC provider

        // });
        const web3authInstance = new Web3Auth({
          clientId, // Get your Client ID from Web3Auth Dashboard
          chainConfig: {
            chainNamespace: "eip155", // Stays the same for Ethereum compatible chains
            chainId: "0x5A", // Replace with Sapphire Devnet chain ID (assuming 0x5A is correct for Sapphire Devnet)
            rpcTarget: "https://sapphire-devnet.oasis.io", // Replace with actual Sapphire Devnet RPC endpoint
          },
          web3AuthNetwork: "sapphire_devnet", // Specify the correct network for Web3Auth
          privateKeyProvider: new ethers.providers.JsonRpcProvider("https://sapphire-devnet.oasis.io"), // Add the correct RPC provider for Sapphire Devnet
        });
        
        setWeb3auth(web3authInstance);

        await web3authInstance.initModal();
        setIsInitialized(true);  // Mark Web3Auth as initialized
      } catch (error) {
        console.error("Error during Web3Auth initialization:", error);

      }

    }
    init();

  }, []);


  async function testWeb3Auth() {
    console.log("sssssssssssssss");
    await web3auth.connect();
  }


  return (
    <div className="App">
      <header className="App-header">
        <h1>Web3Auth x MPC Demo</h1>
        <GoogleOAuthProvider clientId="163872611385-1del04prpa2609r63793tcifihh5v4su.apps.googleusercontent.com">
          <GoogleLogin
            onSuccess={credentialResponse => {
              login(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </GoogleOAuthProvider>

        <button onClick={() => testWeb3Auth()}>Test Web3Auth</button>
        <div>
          <button onClick={() => logout()}>Log out</button>
          <button onClick={() => keyDetails()}>Key Details</button>
          <button onClick={() => getAccounts()}>Get Accounts</button>
          <button onClick={() => getBalance()}>Get Balance</button>
          <button onClick={() => signMessage()}>Sign Message</button>
          <button onClick={() => sendTransaction()}>Send Transaction</button>
          <button onClick={() => inputBackupFactorKey()}>Input backup factor</button>
          <button onClick={() => enableMFA()}>Enable MFA</button>
          <button onClick={() => exportMnemonicFactor()}>Export Mnemonic Factor</button>
          <button onClick={() => getUserInfo()}>Get User Info</button>
          <button onClick={() => getDeviceFactor()}>Get device factor</button>
        </div>
        <div id="console" style={{ whiteSpace: "pre-line" }}>
          <p style={{ whiteSpace: "pre-line" }}> </p>
        </div>
      </header>
    </div>
  );
}

export default WebAuth;
